import './Tab4.css';
import Information from '../Information/Information';
import rasulovVideo from '../../video/ratab5.mp4';
import rasulovPhoto from '../../images/RA.jpg';

function Tab4(props) {
    function handleDisplayClick() {
        props.onShow()
    }

    return (
        <section className='tab4'>
            <Information
                title='Транспозиция яичников'
                onClose = {props.onCloseText}
            >
                <p className='tab4__info-title'>Смотрим Расулова Арсена Османовича:</p>
                <div className='tab4__block'>
                    <div className='tab4__container'>
                        <img className='tab4__img' src={rasulovPhoto} alt='фотография Расулова' />
                        <video className="tab4__video" src={rasulovVideo} controls />
                        <p className='tab4__video-description' onClick={handleDisplayClick}>Транспозиция яичников</p>
                        <div className={`${props.isOpen ? 'tab4__video-textBlock tab4__video-textBlock_opened' : 'tab4__video-textBlock'}`}>
                            <p className='tab4__video-textTitle'>
                                Арсен Османович, расскажите пожалуйста о транспозиции яичников у молодых пациенток перед
                                началом химиолучевой терапии ХЛТ.
                            </p>
                            <p className='tab4__video-text'>
                                "Поскольку, как мы уже выяснили, болезнь больше поражает женщин, чем мужчин в соотношении 7 к 1, к сожалению, у части пациентов болезнь приходит в относительно раннем возрасте. То есть, если мы говорим про онкологические заболевания, про их статистику, то, как правило, это болезнь людей пожилого и старческого возраста.
                                Но плоскоклеточный рак анального канала поражает и женщин молодого возраста, в том числе сохранной детородной функцией, в том числе возможно с нереализованной детородной функцией, и совершенно оправданно и понятно желание молодых и красивых женщин сохранить фертильность, сохранить функцию, несмотря на достаточно серьезный диагноз и достаточно серьезное предстоящее лечение.
                                И здесь тоже есть определенные возможности. Дело в том, что когда проводится химиолучевая терапия, несмотря на то, что сейчас проводится химиолучевая терапия по достаточно хорошим методикам, так называемая модулированная лучевая терапия, которая реализуется с защитой окружающих тканей, при этом с максимальным поражением зоны злокачественной опухоли и пути ее распространения.
                                Тем не менее определенное попадание лучевой терапии не исключается на детородные функции, в частности на яичники, что может в последующем привести к вынужденной стерилизации. И если женщина молодого возраста, то для нее это будет достаточно некомфортно.
                                И в такой ситуации речь идет о транспозиции или перемещении яичников за пределы зоны лучевой терапии. Для чего это делается? В первую очередь, я хочу, чтобы было четкое понимание в этом вопросе, это делается для того, чтобы сохранить функцию яичников. Мы не говорим, что после этого женщина может забеременеть естественным образом и выносить беременность.
                                Нет, речь идет прежде всего о сохранении функции, о сохранении репродуктивной функции, в том числе яичников. Конечно, лучше в этой ситуации заранее подготовить яйцеклетки до начала лечения на случай последующего их оплодотворения.
                                Но даже если это не произошло, при транспозиции яичников функция яичников, как правило, сохраняется, и в последующем существует возможность забора яйцеклеток из этих яичников. Такая процедура выполняется, как правило, лапароскопическим методом. Она делается малоинвазивно. Пациентка легко ее переносит. При этом она сопровождается пересечением маточных труб с двух сторон для того, чтобы перенести яичники на безопасное расстояние.
                                Яичники выносятся из полости малого таза и располагаются слева и справа в латеральных каналах. Рассекается брюшина, создается определенный участок. Туда подшивается яичник. Обязательно необходимо убедиться в том, что сохранено кровоснабжение, что в последующем обеспечить нормальную функцию.
                                Обязательно в этом месте необходимо установить клипсы для того, чтобы в последующем при разметке для радиотерапевта было понимание, где находятся яичники. И такая несложная процедура обеспечивает сохранение функции яичников в последующем. Как правило, сегодня это достаточно нетрудная манипуляция и её надо иметь в виду при планировании лечения у пациенток молодого возраста.
                                "
                            </p>
                        </div>
                    </div>
                </div>
                <div className='tab4__block tab4__block_tab4'>
                    <p className='tab4__info'>
                        Пациентам, как мужчинам, так и женщинам, желающим иметь детей необходимо рассмотреть вопрос о прохождении до химио-лучевой терапии процедуры по заморозке ооцитов (женские), сперматозоидов (мужские), либо эмбриона.
                    </p>
                </div>
            </Information>
        </section>
    )
}

export default Tab4;