import Note from '../Note/Note';

function Main (props) {
    return (
        <main className='main'>
          <Note onOpen={props.onOpen} isOpen={props.isOpen} onShow={props.onShow} isOpened={props.isOpened} onShowed={props.onShowed}/>
        </main>
    )
}

export default Main;