import './Navigation.css';
import { Link,useLocation } from 'react-router-dom';

function Navigation (props) {
  const path = useLocation().pathname;

    return (
        <section className='navigation'>
          <ul className='navigation__list'>
            <li className="navigation__links">
              <Link to="/rekomendatsii" className={path === '/rekomendatsii' ? "navigation__link navigation__link_bold" : "navigation__link"}>Рекомендации</Link>
            </li>
            <li className="navigation__links">
              <Link to="/diagnoz" className={path === '/diagnoz' ? "navigation__link navigation__link_bold" : "navigation__link"} onClick={props.onCloseText}>О диагнозе</Link>
            </li>
            <li className="navigation__links">
              <Link to="/diagnostika" className={path === '/diagnostika' ? "navigation__link navigation__link_bold" : "navigation__link"} onClick={props.onCloseText}>Диагностика</Link>
            </li>
            <li className="navigation__links">
              <Link to="/transpozitsiya" className={path === '/transpozitsiya' ? "navigation__link navigation__link_bold" : "navigation__link"} onClick={props.onCloseText}>Транспозиция яичников</Link>
            </li>
            <li className="navigation__links">
              <Link to="/hlt" className={path === '/hlt' ? "navigation__link navigation__link_bold" : "navigation__link"} onClick={props.onCloseText}>ХЛТ</Link>
            </li>
            <li className="navigation__links">
              <Link to="/posle hlt" className={path === '/posle hlt' ? "navigation__link navigation__link_bold" : "navigation__link"} onClick={props.onCloseText}>После ХЛТ</Link>
            </li>
            <li className="navigation__links">
              <Link to="/khirurgiya" className={path === '/khirurgiya' ? "navigation__link navigation__link_bold" : "navigation__link"} onClick={props.onCloseText}>Хирургия</Link>
            </li>
            <li className="navigation__links">
              <Link to="/kliniki" className={path === '/kliniki' ? "navigation__link navigation__link_bold" : "navigation__link"} >Клиники и ФЦ</Link>
            </li>
          </ul>
        </section>
    )
}

export default Navigation;