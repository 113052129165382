import './Note.css';
import qr from '../../images/qr.png';
import romanov from '../../images/romanov.jpg';
import rasulov from '../../images/rasulov.jpg';
import inst from '../../images/inst.png';
import vk from '../../images/vk.png';
import tg from '../../images/telegram.png';
import yt from '../../images/youtube.png';
import dz from '../../images/dzen.png';
import { useEffect, useState } from 'react';
import rdvideo from '../../video/rdvmain.mp4';
import ramain from '../../video/ravmain.mp4';

function Note(props) {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const resize = () => {
      setTimeout(() => setWidth(window.innerWidth), 200);
    };
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, [])

  function handleClickRa() {
    props.onOpen(ramain);
  }

  function handleClickRd() {
    props.onOpen(rdvideo);
  }

  function handleDisplayClick() {
    props.onShow()
  }

  function handleTextClick() {
    props.onShowed()
  }

  return (
    <section className='note'>
      <div className='note__container'>
        <div className='note__block'>
          <div className='note__information'>
            <p className='note__info'>
              Сайт создан с целью информирования и поддержки пациентов с диагнозом плоскоклеточный рак анального канала, анального края,
              перианальной кожи C 21 C 44.5 по МКБ при поддержке Telegram сообщества пациентов
              <a className='note__link'
                href='https://t.me/+zpdVmwyIunhmOGMy'
                target='blanc'
                title='вступить в Telegram сообщество'>
                «Мы можем все»
              </a>
              и врачей:
            </p>
            <img className='note__qr' alt='qr-code' src={qr} title='сканировать QR-код для перехода в Telegram сообщество' />
          </div>
        </div>
        <div className='note__cards'>
          <div className='note__card'>
            <img className='note__img' src={rasulov} alt='фотография Расулова'></img>
            <div className='note__doctors'>
              <a className='note__doctors-link' href='https://prodoctorov.ru/odincovo/vrach/358448-rasulov' target='blanc' title='перейти на сайт ПРОДОКТОРОВ'>
                Расулов Арсен Османович
              </a>
              <p className='note__card-info'>
                врач-колопроктолог,врач-онколог,доктор медицинских наук,
                заведующий отделением онкоколопроктологии КГ «Лапино - 2» г. Москва
              </p>
              <div className='note__card-contacts'>
                <a className='note__card-link' href='https://www.instagram.com/dr_arsen_rasulov?igsh=MTl3MDFpOHJuMGV0eg==' target='blanc'>
                  <img
                    className='node__card-iconInst'
                    src={inst}
                    alt='иконка инстаграм'
                    title='посмотреть профиль в Instagram'
                  />
                </a>
                <a className='note__card-link' href='https://vk.com/dr_arsen_rasulov' target='blanc'>
                  <img
                    className='node__card-iconVk'
                    src={vk}
                    alt='иконка инстаграм'
                    title='посмотреть профиль в ВКонтакте'
                  />
                </a>
                <a className='note__card-link' href='https://t.me/drRasulov' target='blanc'>
                  <img
                    className='node__card-iconInst'
                    src={tg}
                    alt='иконка telegram'
                    title='перейти в телеграм'
                  />
                </a>
                <a className='note__card-link' href='https://m.youtube.com/@arsenrasulov5517' target='blanc'>
                  <img
                    className='node__card-iconInst'
                    src={yt}
                    alt='иконка YouTube'
                    title='посмотреть видео в YouTube'
                  />
                </a>
                <a className='note__card-link' href='https://dzen.ru/dr_arsen_rasulov?share_to=link' target='blanc'>
                  <img
                    className='node__card-iconVk'
                    src={dz}
                    alt='иконка Яндекс Дзен'
                    title='перейти в Яндекс Дзен'
                  />
                </a>
              </div>
              {width > 646 ?
                <button className='note__button' onClick={handleClickRa}>Посмотреть видео о враче</button> :
                <video className="note__video" src={ramain} controls />
              }
              <p className='note__video-description' onClick={handleDisplayClick}>Немного о себе рассказывает Арсен Османович Расулов</p>
            </div>
          </div>
          <p className={`${props.isOpen ? 'note__video-text note__video-text_opened' : 'note__video-text'}`}>
            "Я, Расулов Арсен Османович, лауреат премии правительства области науки и техники, доктор медицинских наук.
            Специализируюсь на хирургическом лечении злокачественных образований брюшной полости малого таза,
            в большей степени злокачественных образованиях толстой кишки. Руковожу отделением в онкологическом центре “Лапино”."
          </p>
          <div className='note__card note__card_down'>
            <img className='note__img' src={romanov} alt='фотография Романова'></img>
            <div className='note__doctors'>
              <a className='note__doctors-link' href='https://prodoctorov.ru/moskva/vrach/365669-romanov' target='blanc' title='перейти на сайт ПРОДОКТОРОВ'>
                Романов Денис Сергеевич
              </a>
              <p className='note__card-info'>
                врач-онколог, зам. Генерального директора федеральной сети клиник экспертной онкологии «Евроонко» по научной деятельности,
                врач-радиотерапевт, заведующий радиотерапевтическим отделением ЧУЗ «ЦКБ «РЖД-Медицина»,
                доцент кафедры онкологии и лучевой терапии МГМСУ им. А.И. Евдокимова
              </p>
              {width > 646 ?
                <button className='note__button' onClick={handleClickRd}>Посмотреть видео о враче</button> :
                <video className="note__video" src={rdvideo} controls />
              }
              <p className='note__video-description' onClick={handleTextClick}>Немного о себе рассказывает Денис Сергеевич Романов</p>
            </div>
          </div>
          <p className={`${props.isOpened ? 'note__video-text note__video-text_opened' : 'note__video-text'}`}>
            "Я врач-радиотерапевт, в какой-то мере врач-онколог, в соответствии с сертификатом, но, на самом деле, важно, 
            что лучевая терапия – это не некая независимая специальность, а это часть онкологии, поэтому нельзя сосредоточиться только 
            на возможностях лучевой терапии.
            Если ты хочешь получать хорошие результаты в лечении своих, доверившихся тебе, пациентов, 
            то ты должен быть и онкологом тоже весьма неплохим и с точки зрения стратегии и тактики, и, в принципе,
            с точки зрения каких-то нюансов лекарственной терапии.
            Я работаю, на самом деле, во многих клиниках, и это не так трудно уточнить в интернете, 
            но два основных моих места работы – это федеральная сеть клиник экспертной онкологии «Евроонко», 
            где я как раз числюсь врачом-онкологом, фактически, конечно, являясь радиотерапевтом, 
            и заодно оказана мне высокая честь быть заместителем генерального директора по научной деятельности. 
            И второй из основных таких мест работы – это “ЦКБ “РЖД - медицина””, там я уже являюсь врачом-радиотерапевтом и 
            заведующим соответствующего отделения."
          </p>
        </div>
        <p className='note__warning'>* meta, instagram, facebook запрещены в РФ</p>
        <div className='note__block note__block_note'>
          <p className='note__info'>
            Информация, размещённая на сайте, а также мнение специалиста носят исключительно ознакомительно-справочный характер
            и не являются медицинской услугой и медицинской помощью по смыслу Федерального закона от 21.11.2011 N 323-ФЗ
            «Об основах охраны здоровья граждан в Российской Федерации» и не заменяют очного приема врача.
            Определение диагноза и методик лечения остаётся прерогативой Вашего лечащего врача.
          </p>
        </div>
      </div>
    </section>
  )
}

export default Note;