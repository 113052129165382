import { Link } from "react-router-dom";
import './Burger.css'

function Burger(props) {
  function handleClick () {
    props.onClose();
    props.onCloseText();
  }
  return (
    <section className={`${props.isOpen ? 'burger burger_opened' : 'burger'}`} onClick={props.onClose} >
      <div className="burger__container" onClick={e => e.stopPropagation()}>
        <ul className="burger__list">
          <li className="burger__links">
            <Link to="/" className="burger__link" onClick={handleClick}>Главная</Link>
          </li>
          <li className="burger__links">
            <Link to="/rekomendatsii" className="burger__link " onClick={handleClick}>Рекомендации</Link>
          </li>
          <li className="burger__links">
            <Link to="/diagnoz" className="burger__link" onClick={handleClick}>О диагнозе</Link>
          </li>
          <li className="burger__links">
            <Link to="/diagnostika" className="burger__link" onClick={handleClick}>Диагностика</Link>
          </li>
          <li className="burger__links">
            <Link to="/transpozitsiya" className="burger__link" onClick={handleClick}>Транспозиция яичников</Link>
          </li>
          <li className="burger__links">
            <Link to="/hlt" className="burger__link" onClick={handleClick}>ХЛТ</Link>
          </li>
          <li className="burger__links">
            <Link to="/posle hlt" className="burger__link" onClick={handleClick}>После ХЛТ</Link>
          </li>
          <li className="burger__links">
            <Link to="/khirurgiya" className="burger__link" onClick={handleClick}>Хирургия</Link>
          </li>
          <li className="burger__links">
            <Link to="/kliniki" className="burger__link" onClick={handleClick}>Клиники и ФЦ</Link>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default Burger;