import './Tab7.css';
import Information from '../Information/Information';
import rasulovVideo from '../../video/ratab7.mp4';
import rasulovVideo2 from '../../video/ra2tab7.mp4';
import rasulovPhoto from '../../images/RA.jpg';

function Tab7(props) {
    function handleDisplayClick() {
        props.onShow()
    }

    function handleTextClick() {
        props.onShowed()
    }

    return (
        <section className='tab7'>
            <Information
                title='Наблюдение после хирургического лечения'
                onClose = {props.onCloseText}
            >
                <p className='tab7__block-title'>Смотрим Расулова Арсена Османовича:</p>
                <div className='tab7__block'>
                    <div className='tab7__container'>
                        <img className='tab7__img' src={rasulovPhoto} alt='фотография Расулова' />
                        <video className="tab7__video" src={rasulovVideo} controls />
                        <p className='tab7__video-description' onClick={handleDisplayClick}>Наблюдение после хирургического лечения</p>
                        <div className={`${props.isOpen ? 'tab7__video-textBlock tab7__video-textBlock_opened' : 'tab7__video-textBlock'}`}>
                            <p className='tab7__video-textTitle'>
                                Когда может понадобиться хирургическое лечение?
                                Может ли операция быть органосохранна?
                                Возможности реконструктивной хирургии в случае выполнения БПЭ при инфильтрации/поражении опухолью женских половых органов?
                            </p>
                            <p className='tab7__video-text'>
                                "Хирургическое лечение рака анального канала возможно в двух случаях. Первое — это при ранней стадии опухоли, когда образование достаточно небольших размеров, можно отнести к так называемым ранним формам рака, когда достаточно бывает локального иссечения с отступом желательно не менее 1 сантиметра при опухоли, без инвазии или без прорастания в подлежащие структуры.
                                И второй момент — это хирургическое лечение, когда болезнь вернулась, вернулась она локально, после проведения химиолучевой терапии, в течение года 2, 3, 4, до 5 лет существует наиболее высокая вероятность возврата болезни. Если в течение этого срока болезнь вернулась и ее своевременно диагностировали, возврат этой болезни, то в таком случае требуется хирургическое лечение, которое будет заключаться уже в брюшно-промежностная экстирпация прямой кишки.
                                Брюшно-промежностная экстирпация прямой кишки это тотальное удаление прямой кишки заднего прохода и мышц, поднимающих задний проход. Вместе с лимфатическими узлами, с сосудами, с частью периферических нервов и так далее. Это стандартный объем операции. Но бывают ситуации, когда опухоль может выходить за пределы анального канала или прямой кишки.
                                Предположим, опухоль с прорастанием во влагалище или с прорастанием в предстательную железу, если мы говорим про пациентов мужского пола. И в таком случае возникает необходимость выполнения более расширенных хирургических вмешательств с удалением не только прямой кишки и заднего прохода, а с удалением еще и части соседних органов, но  в границе здоровых тканей.
                                Это крайне важно. Это называется R0, когда опухоль удалена в пределах здоровой ткани, то есть она удалена радикально. Почему я это поясняю? Потому что бывает еще R1, когда выявляется при микроскопическом исследовании поражение краев резекции. Это уже хуже, потому что это говорит о том, что там может продолжить рост опухоли. И бывает R2, когда вообще хирург вынужден идти прямо по опухоли.
                                Визуально я это, так сказать, наблюдаю, но у него нет других вариантов, потому что вот такая вот распространенная болезнь. Поэтому мы говорим всегда потенциально о R0 операции, то есть, когда мы удаляем в пределах здоровых тканей. И эта задача достигается более трудным путем при прорастании опухоли в соседний орган.
                                Поскольку это основная задача, то если опухоль проросла во влагалище, то необходимо удалить часть влагалища, а иногда бывает, что и значительную часть влагалища, для того чтобы обеспечить R0 результат. А в таком случае, если женщина молодого возраста, и, конечно же, речь идет о качестве ее жизни в дальнейшем, возникает другая проблема. Если мы удаляем прямую кишку и анальный канал, и, соответственно, вынуждены удалять влагалище, тогда мы лишаем ее напрочь в последующем естественных отношений с мужчинами.
                                И, конечно, это в определенной степени тоже большая проблема. Понятно, что мы спасаем жизнь, но, с другой стороны, вопрос качества жизни здесь, конечно, не менее важен. И в такой ситуации можно и нужно рассматривать вопрос о сохранении, о восстановлении влагалища.
                                Не о сохранении, а о восстановлении, поскольку так или иначе возникает необходимость ее резекции, то есть удаления пораженной части. В последующем, если есть возможность восстановить, то нужно это предпринимать. Восстановление возможно за счет естественных тканей, собственных тканей организма, когда производится формирование анатомической области из оставшихся стенок влагалища, там проводится дополнительная мобилизация и так далее. Но в некоторых ситуациях это тоже может быть невозможным, поскольку приходится удалять не только задние, но и боковые стенки влагалища, и технически это тоже бывает сложно. В таком случае на помощь может прийти применение имплантатов.
                                То есть имплантов, которые замещают собственную ткань, которые закрывают этот дефект. Соответственно укладывается имплант, ему придаётся определенная форма, он сшивается с частью влагалища, с оставшимися стенками влагалища. Таким образом также формируется влагалище, и в последующем этот имплант приживает, эта зона эпителизируется, и, по сути, возникает возможности для реабилитации.
                                Это, конечно, требует еще в дальнейшем определенных реабилитационных мероприятий, в том числе. Но тем не менее, у молодой красивой женщины появляется шанс восстановить орган, ну и соответственно пользоваться, жить нормальной жизнью.
                                "
                            </p>
                        </div>
                    </div>
                    <div className='tab7__container'>
                        <img className='tab7__img' src={rasulovPhoto} alt='фотография Расулова' />
                        <video className="tab7__video" src={rasulovVideo2} controls />
                        <p className='tab7__video-description' onClick={handleTextClick}>Наблюдение после хирургического лечения по поводу рака анального канала</p>
                        <div className={`${props.isOpened ? 'tab7__video-textBlock tab7__video-textBlock_opened' : 'tab7__video-textBlock'}`}>
                            <p className='tab7__video-textTitle'>
                                Арсен Османович, когда после операции может понадобиться химиотерапия?
                            </p>
                            <p className='tab7__video-text'>
                                "Системная химиотерапия в лечении плоскоклеточного рака анального канала применяется, как правило, при распространенных формах болезни, то есть при четвертой стадии, когда есть отдаленные метастазы. Когда пациенту выполнено хирургическое лечение, если операция выполнена в радикальном объеме и отсутствуют признаки поражения других органов, нет необходимости проведения системной химиотерапии, пациент просто наблюдается на протяжении следующих 5 лет.
                                Однако же в ситуации, когда имеются отдаленные метастазы, системная химиотерапия является главным методом лечения. И существуют определенные стандарты проведения химиотерапии, как правило, Карбоплатин и Паклитаксел в первой линии, но есть еще и альтернативные схемы, они все прописаны в КР (клинические рекомендации).
                                С определенной долей успеха в качестве второй линии используется иммунотерапия, но и, как я говорил, продолжаются исследования по оценке таргетных препаратов, в том числе и иммунных препаратов, в лечении пациентов с запущенным раком анального канала. В целом же, при первой, второй и третьей стадии в самостоятельном виде системная химиотерапия не применяется.
                                Если пациенты выполняют радикально хирургическое лечение и даже в удаленном препарате, в удаленной опухоли были обнаружены пораженные лимфатические узлы, химиотерапия не применяется, потому что она не дает в такой ситуации никакого выигрыша. Считается, что пациент перенес радикальное лечение, и ему нужно просто наблюдаться."
                            </p>
                        </div>
                    </div>
                </div>
                <p className='tab7__info-title'>Основные рекомендуемые обследования и анализы после операции</p>
                <table className='tab7___table'>
                    <tbody>

                        <tr className='tab7__table-line'>
                            <th className='tab7__table-cellTitle'>Наименование</th>
                            <th className='tab7__table-cellTitle'>1-3 год</th>
                            <th className='tab7__table-cellTitle'>4-5 год</th>
                            <th className='tab7__table-cellTitle'>&#62; 5 лет</th>
                        </tr>
                        <tr className='tab7__table-line'>
                            <td className='tab7__tableCell'>ОАК
                                <span className='tab7__tableCell-speq'>*</span>
                            </td>
                            <td className='tab7__tableCell'>Каждые 6 мес.</td>
                            <td className='tab7__tableCell'>1 раз в год</td>
                            <td className='tab7__tableCell'>1 раз в год</td>

                        </tr>
                        <tr className='tab7__table-line'>
                            <td className='tab7__tableCell'>ОАМ
                                <span className='tab7__tableCell-speq'>*</span>
                            </td>
                            <td className='tab7__tableCell'>Каждые 6 мес.</td>
                            <td className='tab7__tableCell'>1 раз в год</td>
                            <td className='tab7__tableCell'>1 раз в год</td>

                        </tr>
                        <tr className='tab7__table-line'>
                            <td className='tab7__tableCell'>БАК
                                <span className='tab7__tableCell-speq'>*</span>
                            </td>
                            <td className='tab7__tableCell'>Каждые 6 мес.</td>
                            <td className='tab7__tableCell'>1 раз в год</td>
                            <td className='tab7__tableCell'>1 раз в год</td>

                        </tr>
                        <tr className='tab7__table-line'>
                            <td className='tab7__tableCell'>Онкомаркеры SCC</td>
                            <td className='tab7__tableCell'>Каждые 6 мес.</td>
                            <td className='tab7__tableCell'>1 раз в год</td>
                            <td className='tab7__tableCell'>1 раз в год</td>

                        </tr>
                        <tr className='tab7__table-line'>
                            <td className='tab7__tableCell'>Консультация врача<br></br> онколога и осмотр</td>
                            <td className='tab7__tableCell'>Каждые 6 мес.
                                <span className='tab7__tableCell-speq'>**</span>
                            </td>
                            <td className='tab7__tableCell'>1 раз в год
                                <span className='tab7__tableCell-speq'>**</span>
                            </td>
                            <td className='tab7__tableCell'>1 раз в год
                                <span className='tab7__tableCell-speq'>**</span>
                            </td>
                        </tr>
                        <tr className='tab7__table-line'>
                            <td className='tab7__tableCell'>Пальпация<br></br>лимфатических узлов<br></br>+УЗИ<br></br>лимфатических<br></br> узлов</td>
                            <td className='tab7__tableCell'>Каждые 6 мес.</td>
                            <td className='tab7__tableCell'>1 раз в год</td>
                            <td className='tab7__tableCell'>1 раз в год</td>
                        </tr>
                        <tr className='tab7__table-line'>
                            <td className='tab7__tableCell'>УЗИ органов<br></br>брюшной полости</td>
                            <td className='tab7__tableCell'>Каждые 6 мес.</td>
                            <td className='tab7__tableCell'>1 раз в год</td>
                            <td className='tab7__tableCell'>1 раз в год</td>
                        </tr>
                        <tr className='tab7__table-line'>
                            <td className='tab7__tableCell'>УЗИ органов<br></br>малого таза</td>
                            <td className='tab7__tableCell'>Каждые 6 мес.</td>
                            <td className='tab7__tableCell'>1 раз в год</td>
                            <td className='tab7__tableCell'>1 раз в год</td>
                        </tr>
                        <tr className='tab7__table-line'>
                            <td className='tab7__tableCell'>МРТ малого таза<br></br>с в/в контрастированием</td>
                            <td className='tab7__tableCell tab7__tableCell_small'>по показаниям</td>
                            <td className='tab7__tableCell tab7__tableCell_small'>по показаниям</td>
                            <td className='tab7__tableCell tab7__tableCell_small'>по показаниям</td>
                        </tr>
                        <tr className='tab7__table-line'>
                            <td className='tab7__tableCell'>Комплекс КТ<br></br>грудной клетки и<br></br>органов брюшной<br></br>полости с в/в<br></br>контрастированием<br></br>или ПЭТ КТ</td>
                            <td className='tab7__tableCell'>1 раз в год</td>
                            <td className='tab7__tableCell'>1 раз в год</td>
                            <td className='tab7__tableCell'>1 раз в год</td>
                        </tr>
                        <tr className='tab7__table-line'>
                            <td className='tab7__tableCell'>Колоноскопия</td>
                            <td className='tab7__tableCell'>Каждые 2-3 года</td>
                            <td className='tab7__tableCell'>Каждые 2-3 года</td>
                            <td className='tab7__tableCell'>Каждые 2-3 года</td>
                        </tr>
                        <tr className='tab7__table-line'>
                            <td className='tab7__tableCell'>Осмотр гинеколога для<br></br>женщин</td>
                            <td className='tab7__tableCell'>1 раз в год</td>
                            <td className='tab7__tableCell'>1 раз в год</td>
                            <td className='tab7__tableCell'>1 раз в год</td>
                        </tr>
                        <tr className='tab7__table-line'>
                            <td className='tab7__tableCell tab7__tableCell_last'>ПАП тест для женщин</td>
                            <td className='tab7__tableCell tab7__tableCell_last'>1 раз в год</td>
                            <td className='tab7__tableCell tab7__tableCell_last'>1 раз в год</td>
                            <td className='tab7__tableCell tab7__tableCell_last'>1 раз в год</td>
                        </tr>
                    </tbody>
                </table>
                <p className='tab7__note'>*- именно онкологического смысла в сдаче этих анализов
                    по факту удаления от окончания лечения нет, смысл в контроле общего состояния организма</p>
                <p className='tab7__note'>У пациентов с высоким риском рецидива срок между обследованиями может быть сокращен
                </p>
                <p className='tab7__note'>** на основании пройденных исследований и обследований</p>
                <div className='tab7__block'>
                    <p className='tab7__block-note'>
                        Не все вышеуказанные исследования и обследования проходят в рамках ОМС данный вопрос необходимо обсуждать со своим врачом.
                        Информация носит рекомендательный характер.
                    </p>
                </div>
            </Information>
        </section>
    )
}

export default Tab7;