import Information from '../Information/Information';
import './CookieInfo.css'
import file1 from '../../files/politika.pdf';

function CookieInfo(props) {
    return (
        <section className='cookieInfo'>
            <Information
                title='Мы используем cookie'
            >
                <p className='cookieInfo__text'>
                    Когда вы посещаете этот сайт, www.analcancer.help,
                    телеграмм сообщество пациентов «Мы можем все»  может использовать общеотраслевую технологию,
                    называемую cookie. Файлы cookie представляют собой небольшие фрагменты данных, которые временно
                    сохраняются на вашем компьютере или мобильном устройстве и обеспечивают более эффективную работу сайта.
                </p>
                <p className='cookieInfo__text'>
                    www.analcancer.help, телеграмм сообщество пациентов «Мы можем все»  использует метрические системы
                    для сбора статистики: «Яндекс.Метрика», подсистему «Цифровая культура», «Рамблер/топ-100», «Медиапульсар»,
                    «Медиатор», Top.Mail.Ru. На основе этих данных мы делаем наш сайт лучше и эффективнее для пользователей.
                </p>
                <p className='cookieInfo__text'>
                    Продолжая пользоваться этим сайтом, вы соглашаетесь на использование cookie и обработку данных в соответствии с
                    <a
                        className='cookieInfo__file'
                        href={file1}
                        target="_blank"
                        rel="noreferrer">
                        Политикой сайта
                    </a>
                    в области обработки и защиты персональных данных.
                </p>
                <div className='cookieInfo__agree'>
                    <h2 className='cookieInfo__agree-title' onClick={props.onShow}>Согласие на обработку персональных данных посетителей сайта</h2>
                    <div className={props.isOpen ? 'cookieInfo__agree-info cookieInfo__agree-info_opened' : 'cookieInfo__agree-info'}>
                        <p className='cookieInfo__agree-text'>
                            Настоящим свободно, своей волей и в своем интересе выражаю свое согласие, www.analcancer.help ,
                            телеграмм сообщество пациентов «Мы можем все» (далее — Оператор) на автоматизированную обработку
                            моих персональных данных в соответствии со следующим перечнем персональных данных:
                        </p>
                        <p className='cookieInfo__agree-text'>
                            — мои запросы как посетителя сайта;<br></br>
                            — системная информация, данные из моего браузера;<br></br>
                            — файлы cookie;<br></br>
                            — мой IP-адрес;<br></br>
                            — установленные на моем устройстве операционные системы;<br></br>
                            — установленные на моем устройстве типы браузеров;<br></br>
                            — установленные на моем устройстве расширения и настройки цвета экрана;<br></br>
                            — установленные и используемые на моем устройстве языки;<br></br>
                            — версии Flash и поддержка JavaScript;<br></br>
                            — типы мобильных устройств, используемых мной, если применимо;<br></br>
                            — географическое положение;<br></br>
                            — количество посещений сайта и просмотров страниц;<br></br>
                            — длительность пребывания на сайте;<br></br>
                            — запросы, использованные мной при переходе на сайт;<br></br>
                            — страницы, с которых были совершены переходы.<br></br>
                        </p>
                        <p className='cookieInfo__agree-text'>
                            Для целей сбора статистики о посетителях сайта Оператор вправе осуществлять обработку моих персональных
                            данных следующими способами: сбор, систематизация, накопление, хранение, обновление, изменение, использование.
                            Оператор может обрабатывать файлы cookie самостоятельно или с привлечением иных сервисов.
                        </p>
                        <p className='cookieInfo__agree-text'>
                            Настоящее согласие вступает в силу с момента моего перехода на сайт Оператора и
                            действует до момента его отзыва
                        </p>
                        <p className='cookieInfo__agree-text'>
                            Согласие действует на период: 50 лет.
                        </p>
                        <p className='cookieInfo__agree-text'>
                            Согласие на обработку персональных данных может быть отозвано мною путем письменного уведомления
                            через телеграмм сообщество пациентов «Мы можем все» об этом не менее чем за один месяц до даты
                            фактического прекращения обработки моих персональных данных Оператором.
                        </p>
                    </div>
                    <p className='cookieInfo__text'>
                        Если вы не хотите использовать cookie, вы можете отключить их в настройках безопасности вашего браузера.
                        Отключение cookie следует выполнить для каждого браузера и устройства, с помощью которого осуществляется вход
                        на сайт.
                    </p>
                    <p className='cookieInfo__text'>
                        Обратите внимание, что в случае, если использование сайтом файлов cookie отключено,
                        некоторые возможности и услуги сайта могут быть недоступны
                    </p>
                </div>
            </Information>
        </section>
    )
}

export default CookieInfo;