import './Tab5.css';
import Information from '../Information/Information';
import romanovPhoto from '../../images/RD.jpg';
import rdvideo from '../../video/rdtab4.mp4';


function Tab5(props) {
    function handleTextClick() {
        props.onShowed()
    }

    return (
        <section className='tab5'>
            <Information
                title='Химиолучевая терапия'
                onClose = {props.onCloseText}
            >
                <p className='tab5__info-title'>Смотрим Романова Дениса Сергеевича:</p>
                <div className='tab5__block'>
                    <div className='tab5__container'>
                        <img className='tab5__img' src={romanovPhoto} alt='фотография Романова' />
                        <video className="tab5__video" src={rdvideo} controls />
                        <p className='tab5__video-description' onClick={handleTextClick}>Химиолучевая терапия</p>
                        <div className={`${props.isOpened ? 'tab5__video-textBlock tab5__video-textBlock_opened' : 'tab5__video-textBlock'}`}>
                            <p className='tab5__video-textTitle'>
                                Денис Сергеевич, пациенту уже поставлен диагноз.
                                Расскажите какими методами лечиться этот вид рака?
                                Основные принципы химиолучевого лечения, что нельзя нарушать?
                            </p>
                            <p className='tab5__video-text'>
                                "Есть две принципиальные ситуации, это некий локализованный или местно-распространённый процесс, то есть это то, что описывается стадиями до 4, не включая её, то есть 1, 2, 3, и метастатический процесс с отдалёнными метастазами, чистый метастатический процесс, это метастазы в печени, в лёгких, это метастазы в отдалённых лимфатических узлах, классически традиционно считается, что лимфоузлы в забрюшинном пространстве тоже отдалённые, но это немножечко не так.
                                Надо понимать, что вот эта жировая клетчатка вдоль сосудов магистральных, полости таза и забрюшинного пространства — это, по сути, единая жировая клетчатка. Поэтому даже если есть поражённые забрюшинные лимфоузлы, то я считаю, что надо таких пациентов лечить радикально и не относиться к ним как к метастатическим. Да, у них больше шансов потом вспыхнуть по отдалённым метастазам, но эти шансы не стопроцентные.
                                Технически их вполне можно постараться вылечить. И, на мой взгляд, надо постараться. Так вот, о метастатических формах, наверное, много говорить не приходится, потому что да, там основа лечения химиотерапия, химио- иммунотерапия, системные методы лечения. Лучевая терапия там тоже может применяться, но для достижения контроля над какими-то отдельными проявлениями болезни.
                                А вот место бы хирургии я бы там не смотрел, кроме каких-то паллиативных ситуаций, где здесь и сейчас метастаз или метастазы сидят таким образом , что это угрожает жизни человека. Мне трудно допустить такую ситуацию все-таки при плоскоклеточном раке анального канала. Гораздо более важно понимать, как лечится первичный рак анального канала, локализованный или местно-распространенный.
                                Если хирург, проктолог, онколог, поставив вам диагноз рак анального канала, начинает рассуждать об операции — разворачивайтесь и бегите. Плоскоклеточный рак анального канала не лечится хирургически в качестве первого этапа лечения, категорически нет. Если в вашем городе есть проблемы с лучевой терапией, большие очереди, сломался аппарат, нет лучевой терапии, если вы идёте по линии ОМС (обязательное медицинское страхование), вас обязаны маршрутизировать в другую организация, другой город, где вам сделают лучевую терапию.
                                Если вы видите, что выбиваются сроки, не хотят вас принимать в соседнем городе, ещё какие-либо причины, сделайте это своими силами, бегите туда, где вас полечат правильно. И не теряйте время, оно работает не на вас.
                                Абсолютный стандарт лечения рака анального канала, повторюсь, локализованного или местно-распространённого, то есть с поражёнными лимфоузлами или с прорастанием, допустим во влагалище, это химиолучевая терапия. И по-хорошему химиолучевая терапия вас должна излечить даже от местно-распространённого рака, даже на третьей стадии. Хорошо сделанная химиолучевая терапия в полном объёме с соблюдением всех правил, и так далее, она должна носить излечивающий характер.
                                Прям запомните это. Не надо закладываться на то, что сейчас как-нибудь полечат, а потом прооперируют. Нет. Должна излечить химиолучевая терапия. Почему не излечивает? Потому что неправильно выбираются объёмы облучения. Особенно, когда, делается пациенту МРТ, хотя всякое бывает, не делается ПЭТ КТ, соответственно, не находят поражённые лимфоузлы, неправильно выбирают режим дозирования лучевой терапии.
                                У нас до сих пор, к сожалению, есть несколько схем, которые можно использовать с интегрированным бустом, с последовательным бустом, это уже такая внутренняя кухня лучевой терапии. Там основная идея, что каждый объём должен получить свою определённую дозу, не больше, не меньше. Там бывает разброс, но он весьма небольшой.
                                А у нас иногда подводят либо избыточную дозу, которая совершенно не нужна этой очень чувствительной к лучевой терапии болезни, либо, наоборот, какие-то объекты получают недостаточную дозу, или, например, делается перерыв. Перерыв — это страшнейший враг результатов лучевой терапии. Это просто безобразие, любые перерывы должны быть исключены, кроме тех, которые по медицинским показаниям.
                                То есть, если продолжение лечения ставит под угрозу жизнь пациента по каким-то причинам, обрушилась кровь до катастрофических значений или другие причины, то тогда да, тогда мы вынуждены прерваться, и то надо стараться минимизировать этот перерыв и потом пытаться компенсировать дозой лучевой нагрузки. А вот если это, например, запланированная история, что мы раньше так всегда делали, делали перерывы пациентам и, к сожалению, сейчас есть такие врачи, но я надеюсь, что это всё через некоторое время уйдёт полностью в прошлое.
                                Такие врачи считают, что посередине курса должны сделать перерыв, чтобы пациент отдохнул, восстановился от лучевых реакций и так далее. Это преступление, другого слова для этого у меня нет. Это заведомо вводит потенциально излечимого пациента в серьёзную зону риска не излечиться и потом, к сожалению, даже погибнуть от этого заболевания. Перерывы недопустимы.
                                Да, лучевая терапия, химиолучевая терапия ассоциирована с достаточно тяжелыми лучевыми реакциями в этой зоне, реакциями кожи, реакциями самого анального канала. Это очень болезненно, потом после этого идёт достаточно длительное восстановление, да, это всё есть, но это надо перетерпеть. Я всегда пациенткам пытаюсь сказать, что это примерно, как беременность, это такой тяжелый путь, который должен увенчаться огромным счастьем, там рождением ребенка, а здесь, соответственно, излечением от страшного заболевания.
                                Врач должен подсказывать, какие мази следует применять, чем обезболиваться, как питаться, как это все преодолевать, но, к сожалению, мы не умеем обманывать биологию, физику, анатомию, что есть, то есть. Это тяжелый курс лечения, я всегда предупреждаю пациенток, но я всегда обещаю им, что потом когда-то наступит благоденствие.
                                Так вот, если мы не излечили первичную опухоль, как раз чаще всего в силу перечисленных причин, то да, действительно, вот там хирургия может прийти на помощь, но это не очень приятно. Это калечащая операция с выводом пожизненной колостомы (хирургическое формирование отверстия на брюшной стенке, через которое прокладывается конец толстой кишки для вывода кала из организма). Да, это лучше, чем смерть от этого заболевания, безусловно.
                                И в общем-то, я точно знаю, что девушки, которые вынуждены жить с пожизненной колостомой, они на мой сторонний взгляд достаточно быстро приспосабливаются к этой ситуации и по факту живут нормальной жизнью, понимая, что они выиграли более ценное, они выиграли жизнь, они излечились, дай бог, от этого заболевания. Но лучше всё равно до этого не доводить, лучше сделать химиолучевую терапию именно надлежащим, правильным образом.
                                И в этом нет ничего трудного, это некий долг врача, который, к сожалению, почему-то не всегда у нас в стране, ну и не только у нас в стране, надлежащим образом исполняется.
                                Что в плане выбора химиотерапии? Тут сейчас скажу хитрость, это тоже вроде как я не должен говорить. Я строго использую для лечения этого заболевания в параллель с лучевой терапией Митомицин С и Капецитабин. Митомицин С, к сожалению, пропал из аптек России.
                                Производителю невыгодно его завозить в Россию, потому что он не входит в перечень ЖНВЛП (жизненно необходимые и важнейшие лекарственные препараты), и на него есть жёсткая ограниченная цена, которая в условиях нынешней политической международной конъюнктуры, экономической, соответственно очень низкая и за эту цену производителю неинтересно её продавать в Россию. Поэтому у меня пациенты привозят из других стран, из Средней Азии, из Турции и так далее.
                                Либо иногда, что очень редко препарат появляется в аптеках, в сети, в некоторых аптеках. Соответственно, поэтому я всегда пациентов, даже сейчас, даже в этот сложный достаточно период организационный, я прошу всё-таки постараться приобрести Митомицин-С. Объясню, чем он хорош. Это укол вместо капельницы, это очень такое безопасное и мягкое лекарство, которое, например, не в той степени влияет на показатели крови, как тот же самый альтернативный Цисплатин.
                                То есть, скажем так, сочетание Митомицина с Капецитабином на фоне лучевой терапии — это возможность легче перенести лечение с точки зрения организма в целом. Я всегда предлагаю приобрести, у меня, за последние годы, когда эти препараты пропали с прилавков аптек, только одна, по-моему, пациентка проскочила без этого Митомицина, не смогла купить, не захотела, и мы были вынуждены пользоваться Цисплатином.
                                Но это такой лайфхак формально, с точки зрения, если проводим лечение в больнице, мы должны предоставить всё от больницы, включая лекарства. И поэтому большинство коллег, они, конечно, просто переходят на Цисплатин. Говорить о каких-то подробностях и нюансах лучевой терапии трудновато.
                                Всё-таки это уже такое глубокое погружение в профессию, плюс здесь у каждого врача может быть немножко своё видение, повторюсь, немножко разнятся именно вот такие технические подходы того, что мы делаем, поэтому, если вы видите, что доктор готов о вас заботиться, если доктор логичен, адекватен, как-то аргументирует свою позицию, то вполне, он дальше уже сам должен нормально всё сделать, даже не разжёвывая вам какие-то там маленькие тонкости и нюансы, а вот если вы слышите от доктора, что, например, да, мы в середине сделаем перерыв, или если будет дискомфорт и жжение в перианальной области, то мы отправим вас в перерыв, здесь навострите ушки, к такому доктору вам не надо. Более того, понимаете, не надо пытаться переубедить доктора. Если доктор заблуждается и пользуется плохими практиками в своей работе, вы не заставите его пользоваться хорошими.
                                Он может даже под вашим нажимом как-бы согласиться с вами, но всё равно, скажем так, не надо лечиться у таких докторов. Если вы видите, что сразу заведомо вам предлагают что-то неправильное, если доктор считает, что неправильное правильное, что чёрное это белое и наоборот, ищите альтернативу, потому что это может быть чуть ли не один из самых важных моментов вашей жизни, и от того, как вы его преодолеете, будет зависеть вся ваша дальнейшая судьба.
                                И в дополнении к предыдущему моменту, что мы забыли сказать, и это, кстати, важно, из-за того, что болеют много молодых девчонок, простите за такой грубый термин, многие из них ещё не реализовали свой репродуктивный потенциал.
                                То есть они не родили детей, или не родили детей столько, сколько хотели, или, может быть, хотели бы оставить себе возможность родить детей, надо задуматься о криоконсервации яйцеклеток на всякий случай. Понятное дело, что там иногда поджимает время, надо быстрее бежать, делать то, это, пятое, десятое, опять же, стресс, страх и так далее, но об этом стоит подумать. Это возможность при любом развитии событий тем не менее, скажем так, стать биологической матерью.
                                И второе, о чём нужно тоже проговорить с врачом, если девушка ещё далека до момента предполагаемого наступления естественного климакса, то надо подумать о транспозиции яичников. Так вот, что делается? Это лапароскопическая хирургическая операция, когда яичники выводятся из будущей потенциальной зоны облучения, они подшиваются к верхним этажам брюшной полости. Соответственно, за счёт этого мы рассчитываем, что яичники не пострадают от лучевой терапии, и у женщины не наступит преждевременный климакс. Иногда в спешке об этом некогда думать, и врач об этом не проговаривает, не задумывается и сама пациентка. А в дальнейшем что происходит? Её излечили от рака анального канала, да, всё прекрасно.
                                Только, она в 30 лет, к сожалению, вступила в менопаузу, и назад уже не вернешься. Да, есть всякие ГЗТ (гормональная заместительная терапия), еще различные методы, но мы понимаем, что это не то. Поэтому этот момент, несмотря на стресс, панику, страх, ужас, отчаяние, по-хорошему нужно продумывать параллельно сразу, пока вы проходите обследование и заодно заняться этим вопросом.
                                "
                            </p>
                        </div>
                    </div>
                </div>
            </Information>
        </section>
    )
}

export default Tab5;