import './App.css';
import { Routes, Route } from 'react-router-dom';
import Header from '../Header/Header';
import Navigation from '../Navigation/Navigation';
import Menu from '../Menu/Menu';
import Burger from '../Burger/Burger';
import Main from '../Main/Main';
import Tab1 from '../Tab1/Tab1';
import Tab2 from '../Tab2/Tab2';
import Tab3 from '../Tab3/Tab3';
import Tab4 from '../Tab4/Tab4';
import Tab5 from '../Tab5/Tab5';
import Tab6 from '../Tab6/Tab6';
import Tab7 from '../Tab7/Tab7';
import Tab8 from '../Tab8/Tab8';
import Footer from '../Footer/Footer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import VideoPopup from '../VideoPopup/VideoPopup';
import Cookie from '../Cookie/Cookie';
import CookieInfo from '../CookieInfo/CookieInfo';
import { useEffect, useState } from 'react';

function App() {
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [video, setVideo] = useState({});
  const [display, SetDisplay] = useState(false);
  const [text, SetText] = useState(false);

  useEffect(() => {
    const closePopupWithEsc = (e) => {
      if (e.key === "Escape") {
        closeBurgerMenu();
        closePopup();
      }
    };
    window.addEventListener("keydown", closePopupWithEsc);
    return () => window.removeEventListener("keydown", closePopupWithEsc);
  });

  useEffect(() => {
    if (isBurgerOpen) {
      document.body.style.overflow = 'hidden';
      window.scrollTo(0,0);
    } else {
      document.body.style.overflow = 'unset';
    }
  })

  function handleBurgerOpen () {
    setIsBurgerOpen(!isBurgerOpen)
  }

  function handlePopupOpen(video) {
    setIsPopupOpen(!isPopupOpen);
    setVideo(video)
  }

  function closeBurgerMenu () {
    setIsBurgerOpen(false)
  }

  function closePopup () {
    setIsPopupOpen(false);
  }

  function handleDisplayShow () {
    SetDisplay(!display);
  }

  function handleTextShow () {
    SetText(!text);
  }

  function handleTextClose () {
   SetDisplay(false);
   SetText(false);
  }

  return (
    <div className="App">
      <Burger isOpen={isBurgerOpen} onClose={closeBurgerMenu} onCloseText={handleTextClose}/>
      <Header />
      <Navigation onCloseText={handleTextClose}/>
      <Menu isOpen={isBurgerOpen} onBurger={handleBurgerOpen}/>
      <Cookie onCloseText={handleTextClose}/>
      <Routes >
        <Route 
          path='/'
          element={<Main onOpen={handlePopupOpen} isOpen={display} onShow={handleDisplayShow} isOpened={text} onShowed={handleTextShow}/>}
        />
        <Route 
          path='/rekomendatsii'
          element={<Tab1 onCloseText={handleTextClose}/>}
        />
        <Route 
          path='/diagnoz'
          element={<Tab2 isOpen={display} onShow={handleDisplayShow} isOpened={text} onShowed={handleTextShow} onCloseText={handleTextClose}/>}
        />
        <Route 
          path='/diagnostika'
          element={<Tab3 isOpened={text} onShowed={handleTextShow} onCloseText={handleTextClose}/>}
        />
        <Route
          path='/transpozitsiya'
          element={<Tab4 isOpen={display} onShow={handleDisplayShow} onCloseText={handleTextClose}/>} 
        />
        <Route
          path='/hlt'
          element={<Tab5 isOpened={text} onShowed={handleTextShow} onCloseText={handleTextClose}/>} 
        />
        <Route 
          path='/posle hlt'
          element={<Tab6 isOpen={display} onShow={handleDisplayShow} isOpened={text} onShowed={handleTextShow} onCloseText={handleTextClose}/>}
        />
        <Route 
          path='/khirurgiya'
          element={<Tab7 isOpen={display} onShow={handleDisplayShow} isOpened={text} onShowed={handleTextShow} onCloseText={handleTextClose}/>}
        />
        <Route 
          path='/kliniki'
          element={<Tab8 onCloseText={handleTextClose}/>}
        />
        <Route 
          path='/cookies'
          element={<CookieInfo isOpen={display} onShow={handleDisplayShow}/>}
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <VideoPopup 
        isOpen={isPopupOpen}
        onClose={closePopup}
        video={video}
      />
      <Footer />
    </div>
  );
}

export default App;
