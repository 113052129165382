import { Link } from 'react-router-dom';
import './Cookie.css';
import CookieConsent from "react-cookie-consent";

// function Cookie(props) {
//     return (
//         <section className={props.isOpen ? "cookie cookie_opened": "cookie" }>
//             <div className='cookie__container'>
//                 <div className='cookie__info'>
//                     <h2 className='cookie__title'>Мы используем cookie</h2>
//                     <p className='cookie__text'>
//                         Во время посещения сайта www.analcancer.help вы соглашаетесь с тем,
//                         что мы обрабатываем ваши персональные данные с использованием метрических программ.
//                         <Link to="/cookies" className='cookie__about' onClick={props.onCloseText}>Подробнее.</Link>
//                     </p>
//                 </div>
//                 <button className='cookie__btn' onClick={props.onClose}>Принимаю</button>
//             </div>
//         </section>
//     )
// }
function Cookie(props) {
    return (
        <CookieConsent
            location="none"
            buttonText="Принимаю"
            cookieName="myAppCookieConsent"
            style={{ padding: "10px",
                     borderRadius: "5px",
                     margin: "0 auto",
                     right: "0",
                     left: "0",
                     bottom: "0",
                     maxWidth: "800px",
                     background: "#d0d4d5", 
                     color: "#000", 
                     display: "flex", 
                     flexDirection: "column", 
                     justifyContent: "space-between", 
                     alignItems: "center"
                    }}
            contentStyle={{flex: "1 0 0"}}
            buttonStyle={{height: "25px", 
                          margin: "0",
                          fontFamily: "Inter" , 
                          fontStyle: "normal", 
                          fontWeight: "400", 
                          fontSize: "13px", 
                          background: "#fff", 
                          border: "1px solid #434B4D", 
                          borderRadius: "5px", 
                          alignSelf: "center"
                        }}
            expires={18250}
        >
            <h2 className='cookie__title'>Мы используем cookie</h2>
            <p className='cookie__text'>
                Во время посещения сайта www.analcancer.help вы соглашаетесь с тем,
                что мы обрабатываем ваши персональные данные с использованием метрических программ.
                <Link to="/cookies" className='cookie__about' onClick={props.onCloseText}>Подробнее.</Link>
            </p>
        </CookieConsent>
    )
}

export default Cookie;